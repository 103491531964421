import Axios from 'axios'
import { useAuth } from '@clerk/clerk-react'

import { API_URL } from 'src/utils/constants'
import { PortalBorrowerInfoType } from 'src/types'

// Setting axios defaults to send cookies
Axios.defaults.withCredentials = true

interface ApiReturnProps {
  getBorrowerInfo: () => Promise<PortalBorrowerInfoType>
}

export default function useApiE2E(): ApiReturnProps {
  const { getToken } = useAuth()

  return {
    getBorrowerInfo: async () => {
      const token = await getToken()
      const { data } = await Axios.get<PortalBorrowerInfoType>(
        `${API_URL}v1/portal/borrower_info`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        },
      )
      return data
    },
  }
}
