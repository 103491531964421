import { useContext } from 'react'
import { Outlet, Route, useLocation, Navigate } from 'react-router-dom'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { SignedIn, SignedOut } from '@clerk/clerk-react'
import { useAuth } from '@clerk/clerk-react'

import PortalIndex from 'src/portal/index'
import PortalProfileIndex from 'src/portal/profile/index'
import PortalSignin from 'src/portal/auth/signin'
import { GenericError } from 'src/components/Error/GenericError'
import MaintenanceMode from 'src/components/Error/MaintenanceMode'
import PageNotFound from 'src/components/Error/PageNotFound'
import RootLayout from 'src/components/common/RootLayout'
import { LoadingAnimation } from 'src/components/common/animations/Loading'
import { PortalContext } from 'src/portal/contexts/portal'
import { logoImgSvg } from 'src/images'
import { FeatureFlags } from 'src/types'
import { PortalRoutesEnum } from 'src/portal/common'
import { SentryRoutes } from 'src/routers/common'

const AuthenticatedRoutesWrapper = () => {
  return (
    <>
      <SignedOut>
        <PortalSignin />
      </SignedOut>
      <SignedIn>
        <Outlet />
      </SignedIn>
    </>
  )
}

const PortalRouteWrapper = () => {
  const { borrowerId } = useContext(PortalContext)
  const { isMaintenance } = useFlags<FeatureFlags>()

  // TODO: Replace this with the clerk response or backend response
  const isReady = borrowerId || true

  if (isMaintenance) {
    return (
      <RootLayout logo={{ src: logoImgSvg, alt: 'Fig logo' }}>
        <MaintenanceMode />
      </RootLayout>
    )
  }

  if (isReady) {
    return <Outlet />
  }

  return <LoadingAnimation />
}

export default function PortalRouter() {
  const location = useLocation()
  const { isSignedIn } = useAuth()

  return (
    <SentryRoutes>
      <Route element={<PortalRouteWrapper />}>
        <Route
          path={PortalRoutesEnum.PORTAL_SIGN_IN}
          element={
            isSignedIn ? (
              <Navigate to={`/${PortalRoutesEnum.PORTAL_BASE}`} replace={true} />
            ) : (
              <PortalSignin />
            )
          }
        />
        <Route element={<AuthenticatedRoutesWrapper />}>
          <Route index element={<PortalIndex />} />
          <Route path={PortalRoutesEnum.PORTAL_PROFILE} element={<PortalProfileIndex />} />
        </Route>
        <Route
          path={PortalRoutesEnum.PORTAL_ERROR}
          element={
            <RootLayout logo={{ src: logoImgSvg, alt: 'Fig logo' }}>
              <GenericError isUncaughtError={false} />
            </RootLayout>
          }
        />
        <Route
          path="*"
          element={
            <RootLayout logo={{ src: logoImgSvg, alt: 'Fig logo' }}>
              <PageNotFound currentLocation={location.pathname} />
            </RootLayout>
          }
        />
      </Route>
    </SentryRoutes>
  )
}
