import { captureMessage } from '@sentry/react'
import Axios from 'axios'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { FC, ReactNode, createContext, useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { debounce } from 'throttle-debounce'

import useLocalStorage from 'src/hooks/useLocalStorage'
import {
  ApplicationStatus,
  BankingType,
  BootstrapInfoType,
  CachedApplications,
  CheckStatusResponseDataType,
  FailedStatusCheckType,
  OnfidoDecisionType,
} from 'src/types'
import {
  AsyncRequestsInProgressType,
  CachedApplication,
  CreditRenewalDecisionType,
  FeatureFlags,
  OfferItemType,
  OnFidoDecisionStatusEnum,
  OnfidoReviewReasonsEnum,
  StepsEnum,
  StorageKeys,
} from 'src/types/common'
import { captureExceptionHelper, constants, parseUrl } from 'src/utils'
import { segmentAnalytics } from 'src/utils/analytics'
import { isQCResident as getIsQCResident } from 'src/utils/borrower'
import { API_URL } from 'src/utils/constants'

type State = {
  bootstrapInfo: BootstrapInfoType
  maxLoanAmount: number
  minLoanAmount: number
  minLoanIncreaseAmount: number
  maxLoanIncreaseAmount: number
  totalLoanIncreaseAmount: number
  cachedApplications: CachedApplications
  cachedApplication: CachedApplication
  offerList: OfferItemType[]
  bankAccounts: BankingType[]
  repaymentPolicy: boolean
  borrowerId: string
  applicationId: string
  partnerSessionId: string
  jwtApiKey: string
  isApplicationError: boolean
  isApplicationSettled: boolean
  isApplicationDeclined: boolean
  isAuthorizePaymentStartError: boolean
  flinksLoginId: string
  isApplicationExpired: boolean
  app?: string
  isQCResident: boolean
  isBootstrapSuccess: boolean
  startOnfidoPolling: (() => void) | null
  occupationData: string[]
  asyncRequestsInProgress: AsyncRequestsInProgressType
  onfidoDecision: OnfidoDecisionType
  isCheckingAppStatus: boolean
  genericErrorPageError: any
  creditRenewalDecision: CreditRenewalDecisionType
}

type DispatchType = {
  setStep: (value: string) => void
  setBankAccounts: (value: BankingType[]) => void
  setMaxLoanAmount: (value: number) => void
  setMinLoanAmount: (value: number) => void
  setMinLoanIncreaseAmount: (value: number) => void
  setMaxLoanIncreaseAmount: (value: number) => void
  setTotalLoanIncreaseAmount: (value: number) => void
  setCachedApplications: (value: CachedApplications) => void
  setCachedApplication: (value: CachedApplication) => void
  updateCachedApplication: (override: CachedApplication) => void
  setOfferList: (value: OfferItemType[]) => void
  setRepaymentPolicy: (value: boolean) => void
  setBorrowerId: (value: string) => void
  setApplicationId: (value: string) => void
  setBootstrapInfo: (value: BootstrapInfoType) => void
  setJwtApiKey: (value: string) => void
  setPartnerSessionId: (value: string) => void
  setApplicationError: (value: boolean) => void
  setApplicationSettled: (value: boolean) => void
  setApplicationDeclined: (value: boolean) => void
  setApplicationExpired: (value: boolean) => void
  setAuthorizePaymentStartError: (value: boolean) => void
  setFlinksLoginId: (value: string) => void
  setStartOnfidoPolling: (value: () => void) => void | null
  setBootstrapSuccess: (value: boolean) => void
  setOccupationData: (value: string[]) => void
  setOnfidoDecision: (value: OnfidoDecisionType) => void
  setCreditRenewalDecision: (value: CreditRenewalDecisionType) => void
  updateAsyncRequestStatus: (value: keyof AsyncRequestsInProgressType, inProgress: boolean) => void
  updateAsyncRequestStatusCallback: (
    value: keyof AsyncRequestsInProgressType,
    asyncFunc: () => Promise<any>,
  ) => void
  setGenericErrorPageError: (value: any) => void
}

export type OriginationContextProps = State & DispatchType

export const OriginationContext = createContext<OriginationContextProps>(
  {} as OriginationContextProps,
)

interface Props {
  children: ReactNode
}

const FINAL_ROUTES: string[] = [
  StepsEnum.EXPIRED,
  StepsEnum.ORDER_DECLINED,
  StepsEnum.DECLINED,
  StepsEnum.KYC_ID_FAIL,
  StepsEnum.ORDER_DECLINED,
  StepsEnum.NO_APP_ID,
  StepsEnum.ERROR,
  StepsEnum.PAGE_NOT_FOUND,
] // routes that we shouldn't navigate away from

const CHECK_APP_STATUS_ROUTES: string[] = [
  StepsEnum.KYC_VERIFY, // Accounts for Onfido retry flow
  StepsEnum.PAYMENT_METHOD_SELECT,
  StepsEnum.REPAYMENT_SCHEDULE_CONFIRM,
  StepsEnum.ORDER_REVIEW,
  StepsEnum.ORDER_FINISH,
] // routes that we need to check the status of the application

const ONFIDO_RETRY_ROUTES = [
  StepsEnum.KYC_VERIFY,
  StepsEnum.PAYMENT_METHOD_SELECT,
  StepsEnum.BANK_AUTO_CONNECT,
  StepsEnum.REPAYMENT_SCHEDULE_CONFIRM,
  StepsEnum.ORDER_REVIEW,
]

const E2E_STEPS = [StepsEnum.LANDING, StepsEnum.PREQUALIFICATION, StepsEnum.CONSENTS]

/**
 *
 */
const OriginationProvider: FC<Props> = ({ children }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const { isMaintenance, enablePiiRetry } = useFlags<FeatureFlags>()

  const [cachedApplications, setCachedApplications] = useLocalStorage<CachedApplications>(
    StorageKeys.CACHED_APPLICATIONS,
    {},
  )

  const [bootstrapInfo, setBootstrapInfo] = useState<BootstrapInfoType>({})
  const [isBootstrapSuccess, setBootstrapSuccess] = useState(false)
  const [maxLoanAmount, setMaxLoanAmount] = useState(0)
  const [minLoanAmount, setMinLoanAmount] = useState(0)
  const [minLoanIncreaseAmount, setMinLoanIncreaseAmount] = useState(0)
  const [maxLoanIncreaseAmount, setMaxLoanIncreaseAmount] = useState(0)
  const [totalLoanIncreaseAmount, setTotalLoanIncreaseAmount] = useState(0)
  const [offerList, setOfferList] = useState<OfferItemType[]>([])
  const [bankAccounts, setBankAccounts] = useState<BankingType[]>([])
  const [repaymentPolicy, setRepaymentPolicy] = useState<boolean>(true)
  const [borrowerId, setBorrowerId] = useState('')
  const [applicationId, setApplicationId] = useState('')
  const [partnerSessionId, setPartnerSessionId] = useState('')
  const [jwtApiKey, setJwtApiKey] = useState('')
  const [isApplicationError, setApplicationError] = useState(false)
  const [isApplicationSettled, setApplicationSettled] = useState(false)
  const [isApplicationDeclined, setApplicationDeclined] = useState(false)
  const [isApplicationExpired, setApplicationExpired] = useState(false)
  const [isAuthorizePaymentStartError, setAuthorizePaymentStartError] = useState(false)
  const [startOnfidoPolling, setStartOnfidoPolling] = useState<(() => void) | null>(null)
  const [occupationData, setOccupationData] = useState<string[]>([])
  const isQCResident = getIsQCResident(bootstrapInfo?.borrower)
  const [isCheckingAppStatus, setCheckingAppStatus] = useState(false)
  const [genericErrorPageError, setGenericErrorPageError] = useState(
    new Error('Error: User navigated to the Generic Error page'),
  )
  const [onfidoDecision, setOnfidoDecision] = useState<OnfidoDecisionType>({
    attemptCount: 0,
    isApplicationDeclined: false,
    isUserActionComplete: false,
    reviewReason: '',
    status: '',
    shouldRetry: false,
  })

  const [creditRenewalDecision, setCreditRenewalDecision] = useState<CreditRenewalDecisionType>({
    eligible_for_renewal: false,
    decline_reasons: [],
  })

  const [asyncRequestsInProgress, setAsyncRequestsInProgress] =
    useState<AsyncRequestsInProgressType>({
      getOccupation: false,
      getRepaymentSchedule: false,
      saveOffer: false,
      getOnfidoStatus: false,
      saveOccupation: false,
      savePurposeOfLoan: false,
      savePhoneNumber: false,
      paymentProtectionSelection: false,
    })

  const updateAsyncRequestStatus = (
    key: keyof AsyncRequestsInProgressType,
    inProgress: boolean,
  ) => {
    setAsyncRequestsInProgress(prevState => ({ ...prevState, [key]: inProgress }))
  }

  const updateAsyncRequestStatusCallback = (
    key: keyof AsyncRequestsInProgressType,
    asyncFunc: () => Promise<any>,
  ) => {
    updateAsyncRequestStatus(key, true)
    asyncFunc()
      .catch(() => {
        // pass, let async func handle its own errors
      })
      .finally(() => updateAsyncRequestStatus(key, false))
  }

  const cachedApplication: CachedApplication = cachedApplications[applicationId] ?? {}
  const setCachedApplication = (newCachedApp: CachedApplication) => {
    setCachedApplications(prevCachedApps => ({ ...prevCachedApps, [applicationId]: newCachedApp }))
  }
  const updateCachedApplication = (cachedAppOverride: CachedApplication) => {
    setCachedApplications(prevCachedApps => ({
      ...prevCachedApps,
      [applicationId]: { ...prevCachedApps[applicationId], ...cachedAppOverride },
    }))
  }

  useEffect(() => {
    debouncedPageView.current({
      path: `/${parseUrl().currentStep}`,
      title: document.title,
      referrer: document.URL,
      url: document.URL,
    })
  }, [location.key])

  const handleOnfidoRedirect = (currentStep: string, path: string) => {
    const { attemptCount, status } = onfidoDecision
    if (status === OnFidoDecisionStatusEnum.DECLINED) {
      return StepsEnum.DECLINED
    }

    // prevent loop from loading to verify
    if (currentStep === StepsEnum.KYC_LOADING && path === StepsEnum.KYC_VERIFY) {
      return null
    }

    if (
      currentStep === StepsEnum.KYC_RETRY_GENERIC ||
      currentStep === StepsEnum.KYC_IMAGE_QUALITY_RETRY ||
      (enablePiiRetry && currentStep === StepsEnum.KYC_PII_RETRY)
    ) {
      return null
    }

    if (!enablePiiRetry && onfidoDecision?.reviewReason === OnfidoReviewReasonsEnum.PII_MISMATCH) {
      return null
    }

    // happy path
    if (!onfidoDecision?.reviewReason) {
      if (attemptCount === 0 || attemptCount === 1) {
        // user is coming back to onfido step - force to skip to payment method select
        if (
          path === StepsEnum.KYC_VERIFY &&
          (onfidoDecision?.isUserActionComplete ||
            bootstrapInfo?.application?.is_onfido_user_action_complete)
        ) {
          return StepsEnum.PAYMENT_METHOD_SELECT
        }

        // allow the user to continue past bank connect on the first try
        if (
          path === StepsEnum.PAYMENT_METHOD_SELECT ||
          path === StepsEnum.BANK_AUTO_CONNECT ||
          path === StepsEnum.BANK_SUCCESS
        ) {
          return null
        }
      } else {
        // attemptCount is 2 or 3
        // the user just completed onfido retry - go to loading screen
        if (!status && onfidoDecision.isUserActionComplete) {
          return StepsEnum.KYC_LOADING
        }

        // approved case
        if (path === StepsEnum.KYC_VERIFY && status === OnFidoDecisionStatusEnum.APPROVED) {
          return StepsEnum.PAYMENT_METHOD_SELECT
        }
      }
    }

    if (status && status !== OnFidoDecisionStatusEnum.APPROVED) {
      return StepsEnum.KYC_LOADING
    }
    return null
  }

  const setStep = async (path: string) => {
    const { currentStep } = parseUrl()

    if (isMaintenance) {
      captureMessage(
        `Application navigation for Origination disabled when in maintenance mode - current: ${currentStep} | attempted: ${path}`,
        'warning',
      )
      return
    }

    if (path !== currentStep && FINAL_ROUTES.includes(currentStep)) {
      captureMessage(
        `Tried to navigate away from a final route - current: ${currentStep} | attempted: ${path}`,
        'warning',
      )
      return
    }

    // This is set to true prior to navigating to the page to avoid triggering any functionality before teh page load
    // Ex: StepsEnum.ORDER_FINISH will trigger the Authorize Loan call if this is not set first
    if (CHECK_APP_STATUS_ROUTES.includes(path)) {
      setCheckingAppStatus(true)
    }

    navigate(`${jwtApiKey}/${path}`)

    if (CHECK_APP_STATUS_ROUTES.includes(path)) {
      await getStatusCheck()
      setCheckingAppStatus(false)
    }
    if (isApplicationDeclined) {
      navigate(`${jwtApiKey}/${StepsEnum.DECLINED}`)
      return
    }

    if (ONFIDO_RETRY_ROUTES.includes(path as StepsEnum)) {
      const redirectPath = handleOnfidoRedirect(currentStep, path)
      if (redirectPath) {
        navigate(`${jwtApiKey}/${redirectPath}`)
        return
      }
    }
  }

  const getStatusCheck = async () => {
    try {
      const headers: any = {
        'Content-Type': 'application/json',
        authorization: jwtApiKey,
      }

      const { data } = await Axios.get<CheckStatusResponseDataType>(
        `${API_URL}v1/applications/check_status`,
        { headers },
      )

      const failed_type_list = data.data?.failed_type_list
      if (data.data.status === ApplicationStatus.DECLINED) {
        if (failed_type_list?.includes(FailedStatusCheckType.KYC)) {
          navigate(`${jwtApiKey}/${StepsEnum.KYC_ID_FAIL}`)
        } else {
          navigate(`${jwtApiKey}/${StepsEnum.DECLINED}`)
        }
      }
    } catch (err: any) {
      captureExceptionHelper('Error checking status of verification for application', err)
      navigate(`${jwtApiKey}/${StepsEnum.ERROR}`)
    }
  }

  const debouncedPageView = useRef(
    debounce(1000, (properties: any) => {
      const { currentStep } = parseUrl()
      if (!E2E_STEPS.includes(currentStep as StepsEnum)) {
        segmentAnalytics.page(properties)
      }
    }),
  )
  const [flinksLoginId, setFlinksLoginId] = useState<string>('')

  useEffect(() => {
    if (
      bootstrapInfo?.config?.session_id &&
      !bootstrapInfo?.config?.session_complete &&
      constants.TMX_ORG_ID &&
      window?.threatmetrix?.profile
    ) {
      window.threatmetrix.profile(
        'content.fig.ca',
        constants.TMX_ORG_ID,
        bootstrapInfo.config.session_id,
      )
    }
  }, [bootstrapInfo?.config?.session_id])

  const value: OriginationContextProps = {
    isApplicationSettled,
    setApplicationSettled,
    isApplicationExpired,
    setApplicationExpired,
    isApplicationDeclined,
    setApplicationDeclined,
    isAuthorizePaymentStartError,
    setAuthorizePaymentStartError,
    setStep,
    borrowerId,
    setBorrowerId,
    applicationId,
    setApplicationId,
    partnerSessionId,
    setPartnerSessionId,
    bootstrapInfo,
    setBootstrapInfo,
    isBootstrapSuccess,
    setBootstrapSuccess,
    maxLoanAmount,
    setMaxLoanAmount,
    minLoanAmount,
    setMinLoanAmount,
    minLoanIncreaseAmount,
    setMinLoanIncreaseAmount,
    maxLoanIncreaseAmount,
    setMaxLoanIncreaseAmount,
    totalLoanIncreaseAmount,
    setTotalLoanIncreaseAmount,
    cachedApplications,
    setCachedApplications,
    cachedApplication,
    setCachedApplication,
    updateCachedApplication,
    offerList,
    setOfferList,
    bankAccounts,
    setBankAccounts,
    repaymentPolicy,
    setRepaymentPolicy,
    jwtApiKey,
    setJwtApiKey,
    isApplicationError,
    setApplicationError,
    flinksLoginId,
    setFlinksLoginId,
    isQCResident,
    startOnfidoPolling,
    setStartOnfidoPolling,
    occupationData,
    setOccupationData,
    asyncRequestsInProgress,
    updateAsyncRequestStatus,
    updateAsyncRequestStatusCallback,
    isCheckingAppStatus,
    onfidoDecision,
    setOnfidoDecision,
    genericErrorPageError,
    setGenericErrorPageError,
    creditRenewalDecision,
    setCreditRenewalDecision,
  }
  return <OriginationContext.Provider value={value}>{children}</OriginationContext.Provider>
}

export default OriginationProvider
