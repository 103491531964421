import { Typography } from '@mui/material'
import Grid from '@mui/material/Grid2'
import { t } from 'i18next'

import { useConfiguration } from 'src/hooks'
import { useLocalizedFormatters } from 'src/hooks/useLocalizedFormatters'
import { SectionContainer } from 'src/components/EndToEnd/landing/Walmart/components/SectionContainer'

export const TermsSection = () => {
  const walmartDeductValue = 0.12
  const { percentFormat, currencyFormat } = useLocalizedFormatters()
  const { loanLimits } = useConfiguration()

  const disclaimerValues = {
    min_apr: percentFormat(loanLimits?.minApr ? loanLimits?.minApr - walmartDeductValue : 0, 2),
    max_apr: percentFormat(loanLimits?.maxApr ? loanLimits?.maxApr - walmartDeductValue : 0, 2),
    min_amt: currencyFormat(loanLimits?.minLoanAmount, 0),
    max_amt: currencyFormat(loanLimits?.maxLoanAmount, 0),
  }

  return (
    <SectionContainer>
      <Grid>
        <Typography component="p" variant="termsText" marginBottom="16px">
          {t('e2e.Landing.walmart.termsAndConditions.figDisclaimer', disclaimerValues)}
        </Typography>
        <Typography component="p" variant="termsText">
          {t('e2e.Landing.walmart.termsAndConditions.giftCardDisclaimer')}
        </Typography>
      </Grid>
    </SectionContainer>
  )
}
