import { Route, useNavigate } from 'react-router-dom'
import { ClerkProvider } from '@clerk/clerk-react'

import { UnauthenticatedFreshChatWrapper } from 'src/components/common/UnauthenticatedFreshChatWrapper'
import { PrequalificationProvider } from 'src/contexts'
import { AutoSaveProvider } from 'src/contexts/prequalification'
import { PortalProvider } from 'src/portal/contexts/portal'
import { StepsEnum } from 'src/types'
import AppRouterEndToEnd from 'src/routers/affiliate'
import AppRouterOrigination from 'src/routers/origination'
import AppRouterPortal from 'src/routers/portal'
import { PortalRoutesEnum } from 'src/portal/common'
import { OriginationProvider, ConfigurationProvider } from 'src/contexts'
import { SentryRoutes } from 'src/routers/common'
import { PUBLISHABLE_KEY } from 'src/utils/constants'
import { GenericError } from 'src/components/Error/GenericError'
import RootLayout from 'src/components/common/RootLayout'
import { logoImgSvg } from 'src/images'
import { log } from 'src/utils'

export default function AppRouter() {
  const navigate = useNavigate()

  if (!PUBLISHABLE_KEY) {
    log.error('PUBLISHABLE_KEY is not set, GenericError will be shown')
  }

  return (
    <SentryRoutes>
      <Route
        path={`/${StepsEnum.END_TO_END}/*`}
        element={
          <ConfigurationProvider>
            <PrequalificationProvider>
              <AutoSaveProvider>
                <UnauthenticatedFreshChatWrapper shouldShow>
                  <AppRouterEndToEnd />
                </UnauthenticatedFreshChatWrapper>
              </AutoSaveProvider>
            </PrequalificationProvider>
          </ConfigurationProvider>
        }
      />
      <Route
        path={`/${PortalRoutesEnum.PORTAL_BASE}/*`}
        element={
          PUBLISHABLE_KEY ? (
            <ClerkProvider
              routerPush={to => navigate(to)}
              routerReplace={to => navigate(to, { replace: true })}
              publishableKey={PUBLISHABLE_KEY}
              afterSignOutUrl={`/#/${PortalRoutesEnum.PORTAL_BASE}/${PortalRoutesEnum.PORTAL_SIGN_IN}`}
              signInFallbackRedirectUrl={`/#/${PortalRoutesEnum.PORTAL_BASE}/${PortalRoutesEnum.PORTAL_SIGN_IN}`}
            >
              <PortalProvider>
                <AppRouterPortal />
              </PortalProvider>
            </ClerkProvider>
          ) : (
            <RootLayout logo={{ src: logoImgSvg, alt: 'Fig logo' }}>
              <GenericError />
            </RootLayout>
          )
        }
      />
      <Route
        path={`/*`}
        element={
          <ConfigurationProvider>
            <OriginationProvider>
              <AppRouterOrigination />
            </OriginationProvider>
          </ConfigurationProvider>
        }
      />
    </SentryRoutes>
  )
}
