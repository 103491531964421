import * as Sentry from '@sentry/react'
import { useLDClient } from 'launchdarkly-react-client-sdk'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { QueryObserverResult, RefetchOptions, RefetchQueryFilters, useQuery } from 'react-query'

import { retryQueryOptions } from 'src/api/api'
import { useApi, useOriginationContext } from 'src/hooks/index'
import { useChangeLanguage } from 'src/hooks/useChangeLanguage'
import { useOccupations } from 'src/hooks/useOccupations'
import { useOnfidoPolling } from 'src/hooks/useOnfidoPolling'
import { FrenchLanguageKey } from 'src/locales'
import { ApplicationStatus, BootstrapDataResponseType, StepsEnum } from 'src/types'
import { copy, getDeviceType, isEmpty, segmentAnalytics } from 'src/utils'
import {
  DEFAULT_MIN_LOAN_INCREASE_AMOUNT,
  EnvEnum,
  ENVIRONMENT,
  EXPIRY_DETAIL,
  SENTRY_TAGS,
} from 'src/utils/constants'
import { ldIdentify } from 'src/utils/launchDarkly'

type LogoType = {
  src: string
  alt: string
} | null

interface OriginationDependencies {
  isApplicationSettled: boolean
  bootstrapIsLoading: boolean
  bootstrapIsSuccess: boolean
  bootstrapRefetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined,
  ) => Promise<QueryObserverResult<BootstrapDataResponseType>>

  logo: LogoType
}
export default useOrigination
/*
 *
 */
function useOrigination(): OriginationDependencies {
  const { i18n } = useTranslation()
  const { bootstrap } = useApi()
  const [bootstrapIsLoading, setBootstrapIsLoading] = useState(true)
  const [logo, setLogo] = useState<LogoType>(null)
  const {
    jwtApiKey,
    applicationId,
    setStep,
    bootstrapInfo,
    setBootstrapInfo,
    setMaxLoanAmount,
    setMinLoanAmount,
    setMinLoanIncreaseAmount,
    setMaxLoanIncreaseAmount,
    cachedApplications,
    setCachedApplications,
    setApplicationSettled,
    setApplicationExpired,
    setApplicationDeclined,
    setBootstrapSuccess,
    isApplicationSettled,
    setGenericErrorPageError,
  } = useOriginationContext()
  const ldClient = useLDClient()

  useChangeLanguage(jwtApiKey)

  useOnfidoPolling()
  useOccupations()

  useEffect(() => {
    if (bootstrapInfo?.borrower?.borrower_id) {
      ldIdentify({
        ldClient,
        partnerId: bootstrapInfo.partner!.partner_id,
        partnerName: bootstrapInfo.partner!.name,
        borrowerId: bootstrapInfo.borrower.borrower_id,
      })
    }
  }, [bootstrapInfo])

  useEffect(() => {
    if (bootstrapInfo?.partner) {
      const {
        logo: { en: logoSrcEn, fr: logoSrcFr },
        name: partnerName,
      } = bootstrapInfo.partner
      setLogo({
        src: i18n.resolvedLanguage === FrenchLanguageKey ? logoSrcFr : logoSrcEn,
        alt: `${partnerName} logo`,
      })
    }
  }, [bootstrapInfo, i18n.resolvedLanguage])

  const { refetch: bootstrapRefetch, isSuccess: bootstrapIsSuccess } =
    useQuery<BootstrapDataResponseType>(['bootstrap'], () => bootstrap(), {
      enabled: !!applicationId,
      refetchOnWindowFocus: 'always',
      ...retryQueryOptions,
      useErrorBoundary: false,
      onError: (result: any) => {
        const errors = result.response?.data?.errors
        if (errors && errors.length > 0) {
          const error_detail = errors[0]['detail']
          if (error_detail === 'No application with that id exists') {
            setGenericErrorPageError(new Error('No application with that id exists'))
            setStep(StepsEnum.ERROR)
          }
        } else if (result.response?.data?.detail === EXPIRY_DETAIL) {
          const { partner } = result.response.data
          if (!isEmpty(partner)) {
            setBootstrapInfo({ partner })
            setApplicationExpired(true)
            Sentry.captureMessage('Expired jwt token', 'info')
          } else {
            Sentry.captureMessage(
              `Missing partner from expired bootstrap response: ${JSON.stringify(partner)}`,
              'warning',
            )
            setGenericErrorPageError(
              new Error(
                `Missing partner from expired bootstrap response: ${JSON.stringify(partner)}`,
              ),
            )
            setStep(StepsEnum.ERROR)
          }
        }
        setBootstrapIsLoading(false)
      },
      onSuccess: result => {
        if (!result?.data?.application) {
          Sentry.captureMessage(`Bootstrap returned no data for app=${applicationId}`, 'error')
          return
        }
        const { max_loan_amount: applicantMaxLoan, min_loan_amount: applicantMinLoan } =
          result.data.application
        setMaxLoanAmount(applicantMaxLoan)
        setMinLoanAmount(applicantMinLoan)

        const selectedLoanAmount = cachedApplications[applicationId]?.selected_loan_amount
        if (!selectedLoanAmount || selectedLoanAmount > applicantMaxLoan) {
          const updatedCachedApplications = copy(cachedApplications)

          // below is needed to guarantee that cachedApplications contains the applicationId
          // as the useEffect below that handles refreshes may not execute before bootstrap
          updatedCachedApplications[applicationId] = updatedCachedApplications[applicationId] || {}

          updatedCachedApplications[applicationId].selected_loan_amount = applicantMaxLoan
          setCachedApplications(updatedCachedApplications)
        }

        // credit renewals loan info
        if (bootstrapInfo?.active_loan) {
          setMinLoanIncreaseAmount(DEFAULT_MIN_LOAN_INCREASE_AMOUNT)
          setMaxLoanIncreaseAmount(
            bootstrapInfo?.active_loan?.original_loan_amount -
              bootstrapInfo?.active_loan?.credit_limit,
          )
        }

        setBootstrapInfo(result.data)
        const borrower = result.data.borrower
        const application = result.data.application
        const partner = result.data.partner
        Sentry.setUser({
          id: borrower?.borrower_id,
          applicationId: application?.application_id,
          ...(ENVIRONMENT !== EnvEnum.production && {
            name: `${borrower?.borrower_first_name} ${borrower?.borrower_last_name}`,
          }),
        })
        Sentry.setTag(SENTRY_TAGS.partnerId, partner?.partner_id)

        switch (application?.status) {
          case ApplicationStatus.SETTLED:
          case ApplicationStatus.AUTHORIZED: {
            setApplicationSettled(true)
            break
          }
          case ApplicationStatus.DECLINED: {
            setApplicationDeclined(true)
            break
          }
          case ApplicationStatus.EXPIRED: {
            setApplicationExpired(true)
            break
          }
        }

        if (borrower?.borrower_id) {
          segmentAnalytics.identify(borrower.borrower_id, {
            user_id: borrower.borrower_id,
            application_id: application.application_id,
            min_loan_amount: application.min_loan_amount,
            max_loan_amount: application.max_loan_amount,
            user_agent: navigator.userAgent,
            device_type: getDeviceType(),
          })
        }
        setBootstrapIsLoading(false)
      },
    })

  useEffect(() => {
    setBootstrapSuccess(bootstrapIsSuccess)
  }, [bootstrapIsSuccess])

  return {
    isApplicationSettled,
    bootstrapIsLoading,
    bootstrapIsSuccess,
    bootstrapRefetch,
    logo,
  }
}
