import { Box, Grid, Link, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Tooltip } from 'react-tooltip'

import { IconStyle } from 'src/components/CreditRenewal/components/IconStyle'
import { LineItem } from 'src/components/CreditRenewal/components/LineItem'
import { TableDivider } from 'src/components/ReviewOrder/styles'
import { useLocalizedFormatters } from 'src/hooks/useLocalizedFormatters'
import { questionMark, walletIcon } from 'src/images'
import theme from 'src/themes'

export interface DisbursalInfoProps {
  accountNumber: string
  increasedLoanAmount: number
  outstandingInterest: number
  disbursalAmount: number
}
export const DisbursalInfo = ({
  accountNumber,
  increasedLoanAmount,
  outstandingInterest,
  disbursalAmount,
}: DisbursalInfoProps) => {
  const { t } = useTranslation()
  const { currencyFormat } = useLocalizedFormatters()

  return (
    <>
      <TableDivider style={{ marginBottom: 0 }} />

      <Grid item textAlign="center" border={`solid 1px ${theme.color.grey3}`} borderRadius="3px">
        <Box
          sx={{
            backgroundColor: theme.color.brand5,
            height: '44px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <IconStyle
            src={walletIcon}
            sx={{ display: 'inline', mr: '4px' }}
            alt={t('CreditRenewals.Review.disbursalInfoTitle')}
          />
          <Typography variant="body2" display="inline" color={theme.color.grey9} fontWeight={500}>
            {t('CreditRenewals.Review.disbursalInfoTitle')}
          </Typography>
        </Box>
        <Box px="24px" py="24px">
          <Typography variant="body2" color={theme.color.grey9} my={'18px'} display={'inline'}>
            {t('CreditRenewals.Review.disbursalInfoDescriptionPreToolTip', {
              disbursalAmount: currencyFormat(disbursalAmount),
            })}
          </Typography>
          <img
            src={questionMark}
            alt={t('CreditRenewals.tooltipAlt')}
            width="20px"
            height="20px"
            data-tooltip-id="loanDetailsLearnMore"
            style={{ verticalAlign: 'middle', cursor: 'pointer', marginRight: '5px' }}
          />
          <Tooltip
            id="loanDetailsLearnMore"
            place="bottom"
            style={{
              background: 'white',
              color: 'black',
              fontSize: '14px',
              borderRadius: '8px',
              padding: '10px 15px',
              width: '340px',
              height: '144px',
              boxShadow: '0px 2px 6px 2px #00000026',
              textAlign: 'left',
            }}
            clickable
            opacity="1"
          >
            {t('CreditRenewals.Review.disbursalInfoHoverText')}
            <Link
              sx={{
                display: 'block',
                marginTop: 1,
                fontSize: 14,
              }}
              variant="linkMedium"
              href={t('CreditRenewals.Review.disbursalInfoLearnMoreLink')}
              target="_blank"
              aria-label={t('CreditRenewals.Review.disbursalInfoLearnMoreLink')}
            >
              {t('CreditRenewals.Review.disbursalInfoLearnMore')}
            </Link>
          </Tooltip>
          <Typography variant="body2" color={theme.color.grey9} my={'18px'} display={'inline'}>
            {t('CreditRenewals.Review.disbursalInfoDescriptionPostToolTip', {
              accountNumber,
            })}
          </Typography>

          <Grid pt="12px" container textAlign={'left'} justifyContent={'space-between'}>
            <LineItem
              title={t('CreditRenewals.Review.disbursalInfoIncreasedLoanAmount')}
              content={currencyFormat(increasedLoanAmount)}
            />
            <LineItem
              title={t('CreditRenewals.Review.disbursalInfoOutstandingInterest')}
              content={`(${currencyFormat(outstandingInterest)})`}
            />
            <LineItem
              title={t('CreditRenewals.Review.disbursalInfoDisbursalAmount')}
              content={currencyFormat(disbursalAmount)}
            />
          </Grid>
        </Box>
      </Grid>
      <TableDivider style={{ marginBottom: 0 }} />
    </>
  )
}
