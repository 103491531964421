import { FC, createContext, ReactNode, useState, useEffect } from 'react'
import { useAuth } from '@clerk/clerk-react'

import useApiPortal from 'src/portal/hooks/useApiPortal'

type PortalContextProps = {
  borrowerId: string
  authJWT: string
  setBorrowerId: (value: string) => void
}

export const PortalContext = createContext<PortalContextProps>({} as PortalContextProps)

interface Props {
  children: ReactNode
}

export const PortalProvider: FC<Props> = ({ children }) => {
  const [borrowerId, setBorrowerId] = useState('')
  const { getBorrowerInfo } = useApiPortal()
  const { isSignedIn } = useAuth()

  useEffect(() => {
    // Fetch borrowerId
    if (isSignedIn) {
      getBorrowerInfo()
    }
  }, [isSignedIn])

  const value = {
    borrowerId,
    authJWT: 'TODO',
    setBorrowerId,
  }

  return <PortalContext.Provider value={value}>{children}</PortalContext.Provider>
}

export default PortalProvider
