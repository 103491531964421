import { Modal } from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'

import { LoadingAnimation } from 'src/components/common/animations/Loading'
import { PrimaryCTA, SecondaryCTA } from 'src/components/common/Buttons'
import { useLoanIncreaseRequestButton } from 'src/components/CreditRenewal/hooks/useLoanIncreaseRequestButton'
import { RequestLoanAmountIncreaseBoxModal } from 'src/components/CreditRenewal/RequestLoanAmountIncreaseBoxModal'
import { RequestLoanAmountIncreaseModal } from 'src/components/CreditRenewal/RequestLoanAmountIncreaseModal'
import ErrorPage from 'src/components/Error/shared/ErrorPage'
import { PleaseReachOut } from 'src/components/Error/shared/PleaseReachOut'
import { loanExistsImg } from 'src/images'
import { StepsEnum } from 'src/types'
import {
  existingCustomerContactFigClicked,
  existingCustomerHelpCentreClicked,
  existingCustomerLiveChatClicked,
  existingCustomerLoginClicked,
  existingCustomerRequestLoanAmountIncreaseClicked,
} from 'src/utils'
import { Q2_PORTAL_URL } from 'src/utils/constants'

export default ApplicationAlreadySettled
/**
 *
 */
function ApplicationAlreadySettled() {
  const { t } = useTranslation()
  const screen = StepsEnum.SETTLED

  const { shouldShowLoanIncreaseRequestButton, isLoading, isMobile, openModal, setOpenModal } =
    useLoanIncreaseRequestButton()

  const content = (
    <Trans i18nKey="ApplicationAlreadySettled.content">
      Visit your Fig account for details of your loan. Got questions? Visit our help centre to get
      the answers you need. For other concerns{' '}
      <PleaseReachOut
        screen={screen}
        liveChatAnalytics={existingCustomerLiveChatClicked}
        contactFigAnalytics={existingCustomerContactFigClicked}
      />
    </Trans>
  )
  const loginButton = (
    <PrimaryCTA
      href={Q2_PORTAL_URL}
      onClick={() => {
        existingCustomerLoginClicked(t('ApplicationAlreadySettled.loginLabel'), screen)
      }}
      buttonText={t('ApplicationAlreadySettled.loginLabel')}
    />
  )
  const helpCentreButton = (
    <SecondaryCTA
      href={t('HelpDesk.errorUrl')}
      target="_blank"
      onClick={() => {
        existingCustomerHelpCentreClicked(t('common.helpLabel'), screen)
      }}
      buttonText={t('common.helpLabel')}
    />
  )
  const requestLoanAmountIncreaseButton = (
    <SecondaryCTA
      onClick={() => {
        existingCustomerRequestLoanAmountIncreaseClicked(
          t('CreditRenewals.requestButtonLabel'),
          screen,
        )
        setOpenModal(!openModal)
      }}
      buttonText={t('CreditRenewals.requestButtonLabel')}
    />
  )

  const requestLoanAmountButton = (
    <Modal
      open={openModal}
      onClose={() => setOpenModal(!openModal)}
      aria-labelledby="terms-modal-title"
      aria-describedby="terms-modal-description"
    >
      <RequestLoanAmountIncreaseBoxModal isMobile={isMobile}>
        <RequestLoanAmountIncreaseModal
          handleClose={() => setOpenModal(!openModal)}
          isMobile={isMobile}
        />
      </RequestLoanAmountIncreaseBoxModal>
    </Modal>
  )
  if (isLoading) {
    return <LoadingAnimation />
  }
  if (shouldShowLoanIncreaseRequestButton) {
    return (
      <ErrorPage
        image={{ src: loanExistsImg, alt: t('ApplicationAlreadySettled.alt') }}
        title={t('ApplicationAlreadySettled.title')}
        content={content}
        buttons={[loginButton, requestLoanAmountIncreaseButton]}
        extraContent={requestLoanAmountButton}
      />
    )
  }

  return (
    <ErrorPage
      image={{ src: loanExistsImg, alt: t('ApplicationAlreadySettled.alt') }}
      title={t('ApplicationAlreadySettled.title')}
      content={content}
      buttons={[loginButton, helpCentreButton]}
    />
  )
}
