import CloseIcon from '@mui/icons-material/Close'
import { Box, Grid, IconButton, Link, Snackbar, Typography, useMediaQuery } from '@mui/material'
import { useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { LoadingAnimation } from 'src/components/common/animations/Loading'
import { BackButton, PrimaryCTA } from 'src/components/common/Buttons'
import { DivRoot } from 'src/components/common/DivRoot'
import { PolicyContainer } from 'src/components/common/Policy'
import { DisbursalInfo } from 'src/components/CreditRenewal/components/DisbursalInfo'
import { ImgStyle } from 'src/components/CreditRenewal/components/ImgStyle'
import { LoanDetailsContainer } from 'src/components/ReviewOrder/Containers/LoanDetailsContainer'
import { PaymentContainer } from 'src/components/ReviewOrder/Containers/PaymentContainer'
import { PaymentProtectionPlanContainer } from 'src/components/ReviewOrder/Containers/PaymentProtectionPlanContainer'
import { RepaymentContainer } from 'src/components/ReviewOrder/Containers/RepaymentContainer'
import { useReviewOrder } from 'src/components/ReviewOrder/hooks/useReviewOrder'
import { TypographyDetail } from 'src/components/ReviewOrder/styles'
import { AgreementTypeEnum, ReviewOrderProps } from 'src/components/ReviewOrder/types'
import { useOriginationContext } from 'src/hooks'
import { reviewImg } from 'src/images'
import theme from 'src/themes'
import { StepsEnum } from 'src/types'
import {
  creditRenewalsReviewPageBackClicked,
  creditRenewalsReviewPageCTAClicked,
  creditRenewalsReviewPageLoaded,
} from 'src/utils'

export default function Review({ nextStep, previousStep }: ReviewOrderProps) {
  const { t } = useTranslation()

  const {
    eligibleForProtection,
    isCheckingAppStatus,
    isEnrolled,
    isAuthorizePaymentStartError,
    isPolicyIncomplete,
    incompleteError,
    hideSnackBar,
    setHideSnackBar,
    isLoadingLoanAgreement,
    isLoadingPadAgreement,
    paymentMethod,
    isGetPaymentLoading,
    prevFirstPaymentDate,
    isVerifyingRequest,
    fetchLoanAgreement,
    fetchPadAgreement,
    handleAgreementChecked,
    handleContinue,
    consents,
    updateConsents,
    bankAccountNumber,
  } = useReviewOrder()

  const { setStep } = useOriginationContext()

  // TODO: temporary variable to be removed once the data is ready
  const disbursalInfoProps = {
    accountNumber: bankAccountNumber,
    increasedLoanAmount: 1500.0,
    outstandingInterest: 921.0,
    disbursalAmount: 4000.0,
  }

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  useEffect(function updateConsentsOnPageLoad() {
    updateConsents()
  }, [])

  useEffect(function trackPageLoadEvent() {
    creditRenewalsReviewPageLoaded('Credit Renewals Review Page', StepsEnum.CREDIT_RENEWAL_REVIEW)
  }, [])

  useEffect(() => {
    if (isAuthorizePaymentStartError) {
      const errorElement = document.getElementById('startDateErrorWrapper')
      errorElement?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [isGetPaymentLoading, isAuthorizePaymentStartError])

  if (isCheckingAppStatus || isVerifyingRequest || isGetPaymentLoading) {
    return <LoadingAnimation />
  }

  return (
    <DivRoot>
      <Grid container>
        <Grid item xs={12} paddingBottom="0px" marginBottom="8px">
          <ImgStyle
            src={reviewImg}
            alt={t('CreditRenewals.Review.alt')}
            isMobile={isMobile}
            role="img"
          />
          <Typography textAlign="center" variant="h1" marginTop="32px">
            {t('CreditRenewals.Review.title')}
          </Typography>
        </Grid>
      </Grid>
      <LoanDetailsContainer startHidden changeLink={StepsEnum.CREDIT_RENEWAL_LOAN_DETAILS} />
      {eligibleForProtection && (
        <Grid container>
          <PaymentProtectionPlanContainer
            isEnrolled={isEnrolled}
            changeLink={StepsEnum.CREDIT_RENEWAL_PAYMENT_PROTECTION}
          />
        </Grid>
      )}
      <Grid container>
        <RepaymentContainer
          prevFirstPaymentDate={prevFirstPaymentDate}
          isEnrolled={isEnrolled}
          changeLink={StepsEnum.CREDIT_RENEWAL_LOAN_DETAILS}
        />
      </Grid>
      <PaymentContainer
        paymentMethod={paymentMethod}
        changeLink={StepsEnum.CREDIT_RENEWAL_LOAN_DETAILS}
      />

      <DisbursalInfo {...disbursalInfoProps} />

      <Grid container>
        <Box id="errorWrapper">
          {incompleteError && (
            <Typography component="span" variant="body2" color={theme.color.error} marginTop="16px">
              Please complete all the required fields
            </Typography>
          )}
        </Box>
        <PolicyContainer
          checked={consents.accept_toc}
          setChecked={(checked: boolean) => {
            handleAgreementChecked(checked, AgreementTypeEnum.TOC)
          }}
          content={
            <>
              <Typography color={theme.color.grey9} variant="body2">
                <Trans
                  i18nKey="ReviewOrder.consents.loanAgreementText"
                  values={{ loanAgreementLabel: t('ReviewOrder.consents.loanAgreementLabel') }}
                >
                  I agree to Fig’s{' '}
                  <Link variant="linkMedium" onClick={fetchLoanAgreement}>
                    t('ReviewOrder.consents.loanAgreementLabel')
                  </Link>
                  <Typography component="span" display="inline" variant="body2" color="primary">
                    *
                  </Typography>
                </Trans>
              </Typography>
              <TypographyDetail>{t('ReviewOrder.consents.loanAgreementFootnote')}</TypographyDetail>
            </>
          }
        />
        <PolicyContainer
          checked={consents.accept_pad}
          setChecked={(checked: boolean) => {
            handleAgreementChecked(checked, AgreementTypeEnum.PAD)
          }}
          content={
            <Typography color={theme.color.grey9} variant="body2">
              <Trans
                i18nKey="ReviewOrder.consents.padAgreementText"
                values={{ padAgreementLabel: t('ReviewOrder.consents.padAgreementLabel') }}
              >
                Fig can automatically withdraw payments that are due from my account according to
                the{' '}
                <Link variant="linkMedium" whiteSpace="nowrap" onClick={() => fetchPadAgreement()}>
                  {t('ReviewOrder.consents.padAgreementLabel')}
                </Link>
                <Typography component="span" display="inline" variant="body2" color="primary">
                  *
                </Typography>
              </Trans>
            </Typography>
          }
        />
      </Grid>
      <Grid container marginTop="32px">
        <Grid item xs={12} id="startDateErrorWrapper">
          <Box>
            {isAuthorizePaymentStartError && (
              <Typography variant="body2" color={theme.color.error} marginBottom="8px">
                Your first payment date cannot be in the past. Please change your Payment Schedule
                above.
              </Typography>
            )}
          </Box>
        </Grid>
        <Grid item xs={12} display="flex" justifyContent="center">
          <PrimaryCTA
            buttonText={t('ReviewOrder.confirmLoanLabel')}
            onClick={() => {
              creditRenewalsReviewPageCTAClicked('Submit', StepsEnum.CREDIT_RENEWAL_REVIEW)
              handleContinue(nextStep)
            }}
            disabled={isPolicyIncomplete}
            aria-disabled={isPolicyIncomplete}
          />
        </Grid>
        <Grid item xs={12} textAlign="center" marginTop="16px" marginBottom="8px">
          <Typography variant="body2" color={theme.color.grey6}>
            {t('CreditRenewals.Review.disclaimer')}
          </Typography>
        </Grid>
        <Grid item xs={12} display="flex" justifyContent="center" marginTop="16px">
          <BackButton
            onClick={() => {
              setStep(previousStep)
              creditRenewalsReviewPageBackClicked('Back', StepsEnum.CREDIT_RENEWAL_REVIEW)
            }}
            aria-label={t('common.backlabel')}
          />
        </Grid>
      </Grid>
      <Snackbar
        open={isLoadingLoanAgreement && !hideSnackBar}
        onClose={() => setHideSnackBar(true)}
        color={theme.color.grey9}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        message={t('ReviewOrder.loadingLoanAgreement')}
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={() => setHideSnackBar(true)}
          >
            <CloseIcon />
          </IconButton>,
        ]}
      />
      <Snackbar
        open={isLoadingPadAgreement && !hideSnackBar}
        onClose={() => setHideSnackBar(true)}
        color={theme.color.grey9}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        message={t('ReviewOrder.loadingPad')}
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={() => setHideSnackBar(true)}
          >
            <CloseIcon />
          </IconButton>,
        ]}
      />
    </DivRoot>
  )
}
