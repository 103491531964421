import { useMediaQuery } from '@mui/material'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useState } from 'react'
import { useQuery } from 'react-query'

import { useApi, useOriginationContext } from 'src/hooks'
import theme from 'src/themes'
import { FeatureFlags } from 'src/types'
import { getDaysBetween } from 'src/utils'
import { MAXIMUM_ALLOWED_BOOKING_DAYS } from 'src/utils/constants'

/**
 * Used in ApplicationAlreadySettled.tsx
 * Custom hook to manage the state and visibility of the loan increase request button.
 *
 * @returns {Object}
 * - `shouldShowLoanIncreaseRequestButton` (boolean): Determines if the loan increase request button should be shown.
 * - `isMobile` (boolean): Indicates if the current screen size is considered mobile.
 * - `openModal` (boolean): State to control the visibility of a modal.
 * - `setOpenModal` (function): Function to update the `openModal` state.
 */
export const useLoanIncreaseRequestButton = () => {
  const { isCreditRenewalApplyButtonEnabled } = useFlags<FeatureFlags>()
  const { getAuthorizedTimestamp } = useApi()
  const { bootstrapInfo } = useOriginationContext()

  const [hasUserBookedLoanWithinThirtyDays, setHasUserBookedLoanWithinThirtyDays] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const { isLoading } = useQuery(
    ['getAuthorizedTimestamp', true, false],
    () => getAuthorizedTimestamp(),
    {
      enabled: isCreditRenewalApplyButtonEnabled,
      onSuccess: data => {
        const timestamp = data?.data?.authorized_timestamp
        if (!timestamp) {
          setHasUserBookedLoanWithinThirtyDays(false)
        }
        if (timestamp && bootstrapInfo.system_date) {
          const daysBetween = getDaysBetween(
            new Date(bootstrapInfo.system_date),
            new Date(timestamp),
          )
          if (daysBetween > MAXIMUM_ALLOWED_BOOKING_DAYS) {
            setHasUserBookedLoanWithinThirtyDays(false)
          } else {
            setHasUserBookedLoanWithinThirtyDays(true)
          }
        }
      },
    },
  )
  return {
    shouldShowLoanIncreaseRequestButton:
      isCreditRenewalApplyButtonEnabled && hasUserBookedLoanWithinThirtyDays,
    isLoading,
    isMobile,
    openModal,
    setOpenModal,
  }
}
