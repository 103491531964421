import { useRef } from 'react'
import { throttle } from 'throttle-debounce'

import { useOriginationContext } from 'src/hooks'
import { Navigator } from 'src/types'
import { loanOfferTrackAmountChanged } from 'src/utils/analytics'

// Utility to check if the platform is iOS
export const iOS = () => {
  const platform = (navigator as Navigator).userAgentData?.platform || navigator.platform
  return (
    ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(
      platform,
    ) ||
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document) // iPad on iOS 13 detection
  )
}

export const useThrottledTrackAmountChanged = () => {
  return useRef(
    throttle(2000, (amount: number | number[]) => {
      loanOfferTrackAmountChanged(amount)
    }),
  )
}
// Function to handle loan amount changes
export const HandleLoanAmountChange = (event: Event, selectedLoanAmount: number | number[]) => {
  const { updateCachedApplication } = useOriginationContext()
  const throttledTrackAmountChanged = useThrottledTrackAmountChanged()

  const isIOS = iOS()

  // Mouse events and touch events seem to both fire for some mobile browsers.
  if (isIOS && event.type === 'mousedown') {
    return
  }

  if (typeof selectedLoanAmount === 'number') {
    updateCachedApplication({
      selected_loan_amount: selectedLoanAmount,
      saved_repayment_schedule: null, // Reset repayment schedule to monthly as the amount/offers are now changed.
    })
    throttledTrackAmountChanged.current(selectedLoanAmount)
  }
}
// Function to handle loan increase amount changes (credit renewals)
export const HandleLoanIncreaseAmountChange = (
  event: Event,
  selectedLoanIncreaseAmount: number | number[],
) => {
  const { updateCachedApplication, setTotalLoanIncreaseAmount, bootstrapInfo } =
    useOriginationContext()
  const throttledTrackAmountChanged = useThrottledTrackAmountChanged()

  const isIOS = iOS()

  // Mouse events and touch events seem to both fire for some mobile browsers.
  if (isIOS && event.type === 'mousedown') {
    return
  }

  if (!bootstrapInfo.active_loan) {
    return
  }

  if (typeof selectedLoanIncreaseAmount === 'number') {
    updateCachedApplication({
      selected_loan_increase_amount: selectedLoanIncreaseAmount,
      saved_repayment_schedule: null, // Reset repayment schedule to monthly as the amount/offers are now changed.
    })
    setTotalLoanIncreaseAmount(
      (bootstrapInfo?.active_loan?.original_loan_amount || 0) -
        (bootstrapInfo?.active_loan?.repayment_amount || 0) +
        selectedLoanIncreaseAmount,
    )

    throttledTrackAmountChanged.current(selectedLoanIncreaseAmount)
  }
}
