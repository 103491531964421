import { Grid, Typography, styled } from '@mui/material'
import * as Sentry from '@sentry/react'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

import { PrimaryCTA, SecondaryCTA } from 'src/components/common/Buttons'
import { DivRoot } from 'src/components/common/DivRoot'
import { LoadingAnimation } from 'src/components/common/animations/Loading'
import { useApi, useOriginationContext } from 'src/hooks'
import { thankYouImg } from 'src/images'
import {
  AuthorizeRequestType,
  AuthorizeResponseDataType,
  OnfidoReviewReasonsEnum,
  StepsEnum,
  ApplicationStatus,
} from 'src/types'
import { anyEmpty, goToPartnerClicked, helpCentreClicked } from 'src/utils'
import { ENVIRONMENT, EXPIRY_DETAIL, PRE_AUTH_INVALID_STATUS_DETAIL } from 'src/utils/constants'

const ImgStyle = styled('img')({
  width: '80px',
  height: '80px',
  display: 'block',
  marginLeft: 'auto',
  marginRight: 'auto',
})

interface FinishInputType {
  bootstrapRefetch: () => void
}

export default Finish
/**
 *
 */
function Finish({ bootstrapRefetch }: FinishInputType) {
  const [disbursalDay, setDisbursalDay] = useState('')
  const {
    cachedApplication,
    setStep,
    partnerSessionId,
    setAuthorizePaymentStartError,
    bootstrapInfo,
    asyncRequestsInProgress,
    isCheckingAppStatus,
    onfidoDecision,
    setGenericErrorPageError,
    setApplicationSettled,
  } = useOriginationContext()
  const { savePartnerSession, authorize } = useApi()
  const { t } = useTranslation()

  const cachedConsents = cachedApplication.consents!
  const authorizeConsents: AuthorizeRequestType = {
    accept_pad: cachedConsents.accept_pad,
    accept_toc: cachedConsents.accept_toc,
    accept_policy: cachedConsents.accept_policy,
    check_fico: cachedConsents.check_fico,
  }

  const timeoutRef = useRef<NodeJS.Timeout | undefined>()
  const timeToWait = 2 * 60 * 1000
  const asyncRequestsAreComplete = Object.values(asyncRequestsInProgress).every(
    inProgress => !inProgress,
  )

  useEffect(() => {
    if (!asyncRequestsAreComplete) {
      timeoutRef.current = setTimeout(() => {
        Sentry.captureMessage(
          `Async Requests are still loading after ${timeToWait / (60 * 1000)}m`,
          {
            level: 'error',
            extra: { asyncRequestsInProgress },
          },
        )
      }, timeToWait)
    }

    const cleanup = () => clearTimeout(timeoutRef.current)

    if (asyncRequestsAreComplete) {
      cleanup()
    }

    return cleanup
  }, [asyncRequestsAreComplete])

  const { isSuccess: isAuthorizeSuccess } = useQuery<AuthorizeResponseDataType>(
    ['authorize', authorizeConsents],
    () => authorize(authorizeConsents),
    {
      useErrorBoundary: false,
      enabled:
        asyncRequestsAreComplete &&
        !isCheckingAppStatus &&
        onfidoDecision?.reviewReason !== OnfidoReviewReasonsEnum.PII_MISMATCH, //SC-15250: Keep user in a loading state indefinitely (retain current behaviour)
      onSuccess: result => {
        setApplicationSettled(
          result.data.status === ApplicationStatus.SETTLED ||
            result.data.status === ApplicationStatus.AUTHORIZED,
        )
        bootstrapRefetch()
        setDisbursalDay(result.data.disbursal_day)
      },
      onError: (result: any) => {
        const { detail, errors, status } = result?.response?.data || {}
        if (
          detail === PRE_AUTH_INVALID_STATUS_DETAIL &&
          (status || '').toLowerCase() === 'declined'
        ) {
          setStep(StepsEnum.ORDER_DECLINED)
          return
        }
        bootstrapRefetch()
        if (detail === EXPIRY_DETAIL) {
          setStep(StepsEnum.EXPIRED)
        } else if (
          (errors?.[0]?.detail || '')
            .toLowerCase()
            .indexOf('start date is less than system date') !== -1
        ) {
          setAuthorizePaymentStartError(true)
          setStep(StepsEnum.ORDER_REVIEW)
        } else {
          Sentry.captureMessage(`Error on authorize ${ENVIRONMENT}`, {
            level: 'error',
            extra: { result, errors: JSON.stringify(errors, null, 2) },
          })
          setGenericErrorPageError(
            new Error(`Error on authorize ${ENVIRONMENT}: ${JSON.stringify(errors, null, 2)}`),
          )
          setStep(StepsEnum.ERROR)
        }
      },
    },
  )

  const { refetch: refetchPartnerSessionId } = useQuery(
    ['savePartnerSessionId', partnerSessionId],
    () => savePartnerSession(partnerSessionId),
    {
      enabled: false,
      useErrorBoundary: false,
      onError: () => {
        Sentry.captureMessage(
          `Save partner session encountered an error at Finish page in ${ENVIRONMENT}`,
          'warning',
        )
      },
    },
  )

  useEffect(() => {
    if (!partnerSessionId) {
      return
    }
    refetchPartnerSessionId()
  }, [])

  const { name: partnerName, dashboard_page_url: partnerUrl } = bootstrapInfo?.partner ?? {}
  useEffect(() => {
    if (anyEmpty(partnerName, partnerUrl)) {
      Sentry.captureMessage(
        `Missing partner info: ${JSON.stringify(bootstrapInfo?.partner)}`,
        'warning',
      )
    }
  }, [])
  const goToPartnerLabel = t('Finish.goToPartnerLabel', { partnerName })
  const helpCenterLabel = t('Finish.helpCentre.label')

  if (!isAuthorizeSuccess || isCheckingAppStatus) {
    return <LoadingAnimation subtitle={t('Finish.loading')} />
  }

  return (
    <DivRoot>
      <Grid container>
        <Grid item xs={12} paddingBottom={'0px'} marginBottom={'16px'}>
          <ImgStyle src={thankYouImg} alt="Tada success icon" />
          <Typography textAlign="center" variant="h1" marginTop={'32px'}>
            {t('Finish.title')}
          </Typography>
          <Typography textAlign="center" variant="body2" marginTop={'16px'}>
            {t('Finish.disbursalDate', { disbursalDay })}
          </Typography>
          <Typography display="block" textAlign="center" variant="label" marginTop={'16px'}>
            {t('Finish.questionsTitle')}
          </Typography>
          <Typography textAlign="center" variant="body2" marginTop={'16px'}>
            {t('Finish.questionsContent')}
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} display="flex" justifyContent="center">
          <PrimaryCTA
            href={t('Finish.helpCentre.url')}
            onClick={() => {
              helpCentreClicked(helpCenterLabel, StepsEnum.ORDER_FINISH)
            }}
            buttonText={helpCenterLabel}
          />
        </Grid>
        <Grid
          item
          xs={12}
          display="flex"
          justifyContent="center"
          marginTop="16px"
          marginBottom="28px"
        >
          <SecondaryCTA
            href={partnerUrl}
            onClick={() => {
              goToPartnerClicked(goToPartnerLabel, StepsEnum.ORDER_FINISH)
            }}
            buttonText={goToPartnerLabel}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography
            textAlign="left"
            variant="body1"
            lineHeight={'18px'}
            fontSize={'14px'}
            marginTop={'8px'}
          >
            {t('Finish.termsContent')}
          </Typography>
        </Grid>
      </Grid>
    </DivRoot>
  )
}
