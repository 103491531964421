import { Box, Grid, Link, Slider, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Tooltip } from 'react-tooltip'

import { APRGuarantee } from 'src/components/CreditRenewal/styles'
import { useOriginationContext } from 'src/hooks'
import { useLocalizedFormatters } from 'src/hooks/useLocalizedFormatters'
import { questionMark } from 'src/images'
import theme from 'src/themes'
import { isQCResident } from 'src/utils/borrower'
import { LOAN_AMOUNT_STEP } from 'src/utils/constants'
import { HandleLoanIncreaseAmountChange } from 'src/utils/loanAmount'

export function LoanAmountComponent() {
  const {
    bootstrapInfo,
    cachedApplication,
    minLoanIncreaseAmount,
    maxLoanIncreaseAmount,
    totalLoanIncreaseAmount,
  } = useOriginationContext()

  const selectedLoanIncreaseAmount =
    cachedApplication.selected_loan_increase_amount || maxLoanIncreaseAmount

  const { t } = useTranslation()
  const { currencyFormat, percentFormat } = useLocalizedFormatters()
  const apr = bootstrapInfo?.application?.apr as number

  return (
    <Grid container spacing={0}>
      <Grid item xs={12}>
        <Typography variant="h1" textAlign="center">
          {t('CreditRenewals.LoanDetails.title')}
        </Typography>
      </Grid>
      <Grid item xs={12} marginTop="32px" marginBottom="4px">
        <Typography
          data-testid="loanAmount"
          variant="h2"
          color={theme.color.grey9}
          textAlign="center"
          fontSize="24px"
        >
          {currencyFormat(selectedLoanIncreaseAmount, 0)}
        </Typography>
        <Box marginTop="12px" paddingLeft="0px" paddingRight="0px">
          <Slider
            data-testid="loanAmountSlider"
            value={selectedLoanIncreaseAmount}
            onChange={HandleLoanIncreaseAmountChange}
            aria-label={t('CreditRenewals.LoanDetails.title')}
            step={LOAN_AMOUNT_STEP}
            min={minLoanIncreaseAmount}
            max={maxLoanIncreaseAmount}
            aria-valuemin={minLoanIncreaseAmount}
            aria-valuemax={maxLoanIncreaseAmount}
            aria-valuenow={selectedLoanIncreaseAmount}
          />
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="body2" textAlign="left" data-testid="minLoanAmt">
          {currencyFormat(minLoanIncreaseAmount, 0)}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="body2" textAlign="right" data-testid="maxLoanAmt">
          {currencyFormat(maxLoanIncreaseAmount, 0)}
        </Typography>
      </Grid>
      <APRGuarantee container>
        <Typography variant="body2" textAlign="center">
          {t(
            isQCResident()
              ? 'LoanAmount.guaranteedAprLabelQC'
              : 'CreditRenewals.LoanDetails.selectorTitle',
            {
              apr: percentFormat(apr),
              loan_amt: currencyFormat(selectedLoanIncreaseAmount, 0),
              total_amt: currencyFormat(totalLoanIncreaseAmount, 0),
            },
          )}
          <img
            src={questionMark}
            alt={t('CreditRenewals.tooltipAlt')}
            data-tooltip-id="loanDetailsLearnMore"
            role="img"
          />
        </Typography>

        <Tooltip
          id="loanDetailsLearnMore"
          place="bottom"
          style={{
            background: 'white',
            color: 'black',
            fontSize: '14px',
            borderRadius: '8px',
            padding: '10px 15px',
            width: '340px',
            height: '144px',
          }}
          clickable
          opacity="1"
        >
          {t('CreditRenewals.LoanDetails.tooltipContent')}
          <Link
            sx={{
              display: 'block',
              marginTop: 1,
              fontSize: 14,
            }}
            variant="linkMedium"
            //TODO: replace URL
            href="googl.ca"
            target="_blank"
            aria-label="Learn more"
          >
            {t('CreditRenewals.LoanDetails.tooltipLearnMore')}
          </Link>
        </Tooltip>
      </APRGuarantee>
    </Grid>
  )
}
